import React from 'react';
import { appSettings } from 'config/app'
import { tv } from 'tailwind-variants';
import cn from 'classnames'
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const Footer = () => {
   const { t } = useTranslation();

  return (
    <div
      className="grid grid-cols-12 justify-center items-center text-white bg-rose-400 h-full relative space-y-1 px-10"
      style={{ backgroundImage: 'url("/images/header.png")', backgroundSize: 'cover', backgroundPosition: 'top' }
      }>
      <div className="absolute top-0 left-0 w-full h-full bg-[#2C6239]/90 z-0" />
      <div className="grid grid-cols-1 lg:grid-cols-3 z-10 font-light gap-1 col-span-12 lg:col-span-9 xl:col-span-6">
        <div className="flex justify-center items-center">
          <span className="hover:underline">
            <a href="https://cm-vilareal.pt/images/municipio/politica_privacidade.pdf" target="_blank" rel="noreferrer">
              {t('general.privacy-policy')}
            </a>
          </span>
        </div>
        <div className="flex justify-center items-center">
          <span className="hover:underline">
            <a href="https://cm-vilareal.pt/images/municipio/politica_cookies.pdf" target="_blank" rel="noreferrer">
              {t('general.cookie-policy')}
            </a>
          </span>
        </div>
        <div className="flex justify-center items-center">
          <span className="hover:underline">
            <a href="https://www.cm-vilareal.pt/images/municipio/enc_prot_dados.pdf" target="_blank" rel="noreferrer">
              {t('general.data-policy')}
            </a>
          </span>
        </div>
      </div>
      <div className="flex justify-center lg:justify-end items-center z-10 font-light col-span-12 lg:col-span-3 xl:col-span-6">
        <span>{t('general.powered-by')}&nbsp;</span>
        <span
          className={cn(
            text({ color: appSettings.author.color })
          )}
        >
          <a href={appSettings.author.websiteUrl} target="_blank" rel="noreferrer">
            {appSettings.author.name}
          </a>
        </span>
        <span className="ml-2">
          &copy;{moment().year()}
        </span>
      </div>
    </div>
  )
}

export default Footer;

const text = tv({
  base: "hover:underline",
  variants: {
    color: {
      primary: 'text-primary',
      secondary: 'text-secondary',
      light: 'text-light',
      dark: 'text-dark',
      purple: 'text-purple'
    }
  },
  defaultVariants: {
    color: "purple",
  }
});