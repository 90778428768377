import React from 'react';

const VerticalTwo = ({ children }) => {
  return (
    <div className="w-screen h-screen bg-secondary">
      {children}
    </div>
  )
}

export default VerticalTwo;