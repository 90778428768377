import axios from 'axios';

const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type' : 'multipart/form-data',
    //'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
  }
});

const AxiosInterceptor = ({ children }) => {

  axiosConfig.interceptors.response.use(function (response) {
  return response
  }, function (error) {

    if(error.response.status === 401){
     
    }else{
      return Promise.reject(error)
    }
  })

  return children;
}

export default axiosConfig;
export { AxiosInterceptor }