import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'helpers/api';
import { updateLoading } from '../app';
import toast from 'react-hot-toast';
import { t } from 'i18next';

// Create an async thunk for fetch contesta
export const fetchContests = createAsyncThunk('apps/fetchContests', async (params, { dispatch }) => {
  try {
    dispatch(updateLoading(true));

    const response = await api.get('/web/contest/list');

    if (!response.data.ResultCode) {
   
      dispatch(updateLoading(false));

      return false;
    }

    dispatch(updateLoading(false));

    return response?.data?.Data;

  } catch (error) {
    
    dispatch(updateLoading(false));
    
    throw error;
  }
});

// Create an async thunk for fetch contest details
export const contestDetails = createAsyncThunk('apps/contestDetails', async (params, { dispatch }) => {
  try {
    dispatch(updateLoading(true));

    const response = await api.get('/web/contest/' + params);

    if (!response.data.ResultCode) {
   
      toast.error(t(`errors.${response.data.Error}`));
      dispatch(updateLoading(false));

      return false;
    }

    dispatch(updateLoading(false));

    return response?.data?.Data;

  } catch (error) {
    
    dispatch(updateLoading(false));
    
    throw error;
  }
});

// Create an async thunk for fetch contest details
export const contestCheck = createAsyncThunk('apps/contestCheck', async (params, { dispatch }) => {
  const { navigate } = params;
  const { slug } = params;

  try {
    dispatch(updateLoading(true));

    const response = await api.get('/web/check/contest/' + slug);

    if (!response.data.ResultCode) {
   
      toast.error(t(`errors.${response.data.Error}`));
      navigate('/home')
      dispatch(updateLoading(false));

      return false;
    }

    dispatch(updateLoading(false));

    return response?.data?.Data;

  } catch (error) {
    
    dispatch(updateLoading(false));
    
    throw error;
  }
});


const contestSlice = createSlice({
  name: 'contest',
  initialState: {
    list: false,
    selected: false,
    register: false,
    voting: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchContests.fulfilled, (state, action) => {
      state.list = action.payload;
    });
    builder.addCase(contestDetails.fulfilled, (state, action) => {
      state.selected = action.payload;
    });
    builder.addCase(contestCheck.fulfilled, (state, action) => {
      state.register = action.payload.register;
      state.voting = action.payload.voting;
    });
  },
});

export default contestSlice.reducer;
