import React from 'react';
//Settings
import { appSettings } from 'config/app';
//Layouts
import Web from './Web';
import Mobile from './Mobile';

const Layout = ({ children }) => {

  const getLayout = () => {
    switch (appSettings.layout) {
      case 'web':
        return <Web children={children} />
      case 'mobile':
        return <Mobile children={children} />
      default:
        return <Mobile children={children} />
    }


  }

  return getLayout()

}

export default Layout