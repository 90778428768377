import React from 'react';
import { useNavigate } from 'react-router-dom';
import { appSettings } from 'config/app';

const TopBar = () => {
  const navigate = useNavigate()

  return (
    <div
      className="flex items-center relative"
      style={{ backgroundImage: 'url("/images/header.png")', backgroundSize: 'cover', backgroundPosition: 'top' }}
    >
      <div className="absolute top-0 left-0 w-full h-full bg-[#2C6239]/90 z-0" />
      <div className="flex justify-center items-center w-full h-full z-10 py-4">
        <img
          className="cursor-pointer h-full"
          src={appSettings.logo.url}
          alt={appSettings.logo.alt}
          onClick={() => navigate('/voting/natal')}
        />
      </div>
    </div>
  )
}

export default TopBar;