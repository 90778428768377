import React, { Suspense } from "react";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import Layout from "layouts";

//const Homepage = React.lazy(() => import(/* webpackChunkName: "views-home" */ "views/home"));
const Contestpage = React.lazy(() => import(/* webpackChunkName: "views-home" */ "views/contest"));
const VotingPage = React.lazy(() => import(/* webpackChunkName: "views-home" */ "views/voting"));
const ApplicationPage = React.lazy(() => import(/* webpackChunkName: "views-home" */ "views/application"));
const VoteSubmitedPage = React.lazy(() => import(/* webpackChunkName: "views-home" */ "views/voting/submited"));
const NotFoundPage = React.lazy(() => import(/* webpackChunkName: "views-404" */ "views/404"));

const Router = () => {

  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route index element={<Navigate to="/voting/natal" />} />
            {/* <Route exact path="/home" element={<Homepage />} /> */}
            <Route exact path="/contest" element={<Contestpage />} />
            <Route path="/voting/:slug" element={<VotingPage />} />
            <Route path="/application/:slug/submit" element={<ApplicationPage />} />
            <Route path="/voting/submited" element={<VoteSubmitedPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
