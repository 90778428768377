import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'helpers/api';
import { updateLoading } from '../app';
import toast from 'react-hot-toast';
import { t } from 'i18next';

// Create an async thunk for update consent
export const updateConsent = createAsyncThunk('apps/updateConsent', async (params, { dispatch }) => {

  return params;

});

// Create an async thunk for update applicant
export const updateApplicant = createAsyncThunk('apps/updateApplicant', async (params, { dispatch }) => {

  return params;

});

// Create an async thunk for fetch FAQs
export const createApp = createAsyncThunk('apps/createApp', async (params, { dispatch }) => {
  const { navigate } = params;

  try {
    dispatch(updateLoading(true));

    const response = await api.post('/web/application/submit', params);

    if (!response.data.ResultCode) {

      dispatch(updateLoading(false));

      return false;
    }

    dispatch(updateLoading(false));

    toast.success(t('application.submit-success'));
    navigate('/home')

    return response?.data?.Data;

  } catch (error) {

    dispatch(updateLoading(false));

    throw error;
  }
});

// Create an async thunk for reset consent to false
export const resetConsent = createAsyncThunk('apps/resetConsent', async (params, { dispatch }) => {
  return true
});


const applicationSlice = createSlice({
  name: 'application',
  initialState: {
    list: false,
    consent: {
      checked: false
    },
    app: {
      applicant: {
        name: '',
        //birthdate: '',
        address: '',
        postalCode: '',
        city: 'Vila Real',
        country: 'Portugal',
        nif: '',
        cc: '',
        email: '',
        phone: ''
      },
      data: {
        title: '',
        subtitle: '',
        description: '',
        appAddress: '',
        appPostalCode: '',
        appCity: '',
        appCountry: ''
      }
    }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateConsent.fulfilled, (state, action) => {
      state.consent = {
        checked: action.payload
      }
    });
    builder.addCase(updateApplicant.fulfilled, (state, action) => {
      state.app = {
        ...action.payload,
        applicant: action.payload
      };
    });
    builder.addCase(resetConsent.fulfilled, (state, action) => {
      state.consent = {
        checked: false
      }
    });
  },
});

export default applicationSlice.reducer;
