import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'helpers/api';
import { updateLoading } from '../app';
import toast from 'react-hot-toast';
import { t } from 'i18next';

// Create an async thunk for update votes
export const updateVotes = createAsyncThunk('voting/updateVotes', async (params) => {
  return params;
})

// Create an async thunk for update votes
export const updateVote = createAsyncThunk('voting/updateVote', async (params) => {
  return params;
})

// Create an async thunk for submit votes
export const submitVote = createAsyncThunk('apps/submitVote', async (params, { dispatch }) => {

  try {
    //dispatch(updateLoading(true));

    const response = await api.post('/voting/vote', params);

    if (!response.data.ResultCode) {

      toast.error(t(`errors.${response.data.Error}`));
      dispatch(updateLoading(false));

      return false;
    }

    dispatch(updateLoading(false));

    return true;

  } catch (error) {

    dispatch(updateLoading(false));

    throw error;
  }
});

// Create an async thunk for submit votes
export const validateVote = createAsyncThunk('apps/validateVote', async (params, { dispatch }) => {
 
  try {
    dispatch(updateLoading(true));

    const response = await api.post('/voting/validate', params);

    if (!response.data.ResultCode) {

      toast.error(t(`errors.${response.data.Error}`));
      dispatch(updateLoading(false));

      return false;
    }

    toast.success(t('voting.submit-success'));
    dispatch(updateLoading(false));

    return response.data;

  } catch (error) {

    dispatch(updateLoading(false));

    throw error;
  }
});

//Create an async thunk for reset state
export const resetVoting = createAsyncThunk('apps/resetVoting', async (params, { dispatch }) => {
  return true;
})

export const votingSlice = createSlice({
  name: 'app',
  initialState: {
    votes: [],
    vote: {
      submited: false,
      validated: false
    },
  },

  reducers: {},
  extraReducers: builder => {
    builder.addCase(updateVotes.fulfilled, (state, action) => {
      state.votes = action.payload
    });
    builder.addCase(updateVote.fulfilled, (state, action) => {
      state.vote = action.payload
    });
    builder.addCase(submitVote.fulfilled, (state, action) => {
      state.vote = {
        ...state.vote,
        submited: action.payload
      };
    });
    builder.addCase(validateVote.fulfilled, (state, action) => {
      state.vote = {
        ...state.vote,
        validated: action.payload
      };
    });
    builder.addCase(resetVoting.fulfilled, (state, action) => {
      state.votes = [];
      state.vote = {
        submited: false,
        validated: false
      }
    });
  }
})

export default votingSlice.reducer
