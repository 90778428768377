import React, { useEffect } from 'react';
import TopBar from 'components/navigation/TopBar';
import Footer from 'components/navigation/Footer';
import Loading from 'components/modals/Loading';
import { Toaster } from 'react-hot-toast';
import { appSettings } from 'config/app';
import i18n from 'i18n';

const VerticalOne = ({ children }) => {

  useEffect(() => {
    i18n.changeLanguage('pt')
  },[])

  return (
    <div className="flex flex-col h-screen font-light text-primary">
      <Loading />
      <Toaster
        position="top-right"
        toastOptions={appSettings.toast}
      />
      <div className="h-[10%] min-h-[10%] [&>*]:h-full [&>*]:w-full">
        <TopBar />
      </div>
      <div className="flex justify-center flex-1 w-full [&>*]:h-full text-dark p-10 bg-[#EAE8E1] relative z-20">
        <div
          style={{ backgroundImage: 'url("/images/illustration.png")', backgroundSize: 'cover', backgroundPosition: 'center' }}
          className="w-full h-full top-0 left-0 absolute z-20"
        />
        <div className="flex justify-center flex-1 w-full [&>*]:h-full z-20">
          {children}
        </div>
      </div>
      <div className="min-h-[20%] md:min-h-[15%] lg:min-h-[10%] [&>*]:h-full [&>*]:w-full">
        <Footer />
      </div>
    </div>
  )
}

export default VerticalOne;